.elementWrapper{
    width: 50%;
    flex-wrap: wrap;
}

.smallElementWrapper{
    width: 25%;
    flex-wrap: wrap;
}

.elementToLeft{
    padding-right: 10px;
}

.elementToRight{
    padding-left: 10px;
}

.elementToCenter{
    padding: 0px 10px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mainBox{
    padding: 16px 16px 40px 16px;
    max-height: calc(100vh - 56px);
    overflow: auto;
}

.appBackground {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 768px){
    .elementWrapper, .smallElementWrapper{
        width: 100%;
    }

    .elementToLeft, .elementToRight{
        padding-right: 0;
        padding-left: 0;
    }
}

@media screen and (min-width: 600px){
    .mainBox{
        max-height: calc(100vh - 64px);
    }
}

