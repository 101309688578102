.homeMiddle {
  min-height: auto;
  margin: 5vh 0 0 0;
}

.message {
  display: flex;
  justify-content: center;
  align-content: center;
}
