.teamContainer {
  text-align: center;
  width: 50%;
  margin: 5% auto 0 auto;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .teamContainer {
    width: 100%;
    margin: auto;
  }
}

@media only screen and (max-width: 742px) and (orientation: landscape) {
  .teamContainer {
    width: 100%;
    margin: auto;
  }
}
