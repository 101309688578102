@import url(https://fonts.googleapis.com/css?family=Chivo);
@import url(https://fonts.googleapis.com/css?family=Chivo);
@import url(https://fonts.googleapis.com/css?family=Chivo);
.modal {
  background-color: #fff;
  width: 25%;
  padding: 0px 20px 20px 20px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-family: "Chivo", sans-serif !important;
  outline: 0;
}

.modalActions {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.marginTop {
  margin-top: 20px;
}


.loading{
    display: flex;
    align-items: center;
    justify-content: center;
}

.centerLoadingInformation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    font-family: "Chivo", sans-serif !important;
}

.spinner {
    width: 120px;
    text-align: center;
}

.spinner > div {
    width: 15px;
    height: 15px;
    margin-left: 12px;
    background-color: #73879C;
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0)
    }
    40% {
      -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}

[data-pnotify].pnotify.translucent.pnotify-fade-in {
  opacity: 0.88;
}
.elementWrapper{
    width: 50%;
    flex-wrap: wrap;
}

.smallElementWrapper{
    width: 25%;
    flex-wrap: wrap;
}

.elementToLeft{
    padding-right: 10px;
}

.elementToRight{
    padding-left: 10px;
}

.elementToCenter{
    padding: 0px 10px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mainBox{
    padding: 16px 16px 40px 16px;
    max-height: calc(100vh - 56px);
    overflow: auto;
}

.appBackground {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 768px){
    .elementWrapper, .smallElementWrapper{
        width: 100%;
    }

    .elementToLeft, .elementToRight{
        padding-right: 0;
        padding-left: 0;
    }
}

@media screen and (min-width: 600px){
    .mainBox{
        max-height: calc(100vh - 64px);
    }
}


.customHeader {
  align-items: center;
  display: flex;
  height: auto;
  width: 100%;
  margin: 10px 0;
}
.customHeader > .headerLeft {
  align-items: center;
  display: flex;
  width: 20%;
  height: 100%;
}
.customHeader > .headerRight {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  width: 80%;
}

.headerRight > .studentActions {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {

  .customHeader > .headerRight {
    flex-direction: column-reverse;
    padding: 10px;
    align-items: flex-end;
  }

  .headerRight > .studentActions {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}
.titleContainer {
  display: block;
  margin: 10px 0;
  min-height: 40px;
  text-align: center;
  width: 100%;
}

.titleTextContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleContainer > .titleTextContent > .title,
.titleContainer > .subtitle {
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  margin: 0;
  text-align: center;
  font-family: "Chivo", sans-serif !important;
}

.titleContainer > .title {
  text-transform: none;
}

.titleContainer > .reducedSubTitle {
  font-weight: 400;
  font-size: 1.2rem;
}

.blackLine {
  background-color: #010101;
  height: 2px;
  margin-bottom: 21px;
  width: 52px;
  display: inline-table;
}

@media only screen and (max-width: 742px) {
  .titleContainer {
    margin: 2vh 0;
  }

  .titleContainer > .title {
    font-size: 1.3rem;
  }

  .titleContainer > .reducedSubTitle {
    font-size: 1rem;
  }
}

.bottomArea {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 50px;
  margin: 2% 0;
  width: 100%;
}

.logoutButton > .ui.basic.button {
  background-color: var(--neutral-color);
  border: 2.5px solid var(--button-color) !important;
  border-radius: 0 !important;
  color: var(--button-color) !important;
  font-size: var(--normal) !important;
  text-transform: uppercase !important;
  min-width: 135px !important;
  height: 44px;
}

.logoutButton > .ui.basic.button:hover {
  background-color: var(--button-color) !important;
  color: var(--neutral-color) !important;
}
.teamContainer {
  text-align: center;
  width: 50%;
  margin: 5% auto 0 auto;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
  .teamContainer {
    width: 100%;
    margin: auto;
  }
}

@media only screen and (max-width: 742px) and (orientation: landscape) {
  .teamContainer {
    width: 100%;
    margin: auto;
  }
}

.homeMiddle {
  min-height: auto;
  margin: 5vh 0 0 0;
}

.message {
  display: flex;
  justify-content: center;
  align-content: center;
}

