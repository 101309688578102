.customHeader {
  align-items: center;
  display: flex;
  height: auto;
  width: 100%;
  margin: 10px 0;
}
.customHeader > .headerLeft {
  align-items: center;
  display: flex;
  width: 20%;
  height: 100%;
}
.customHeader > .headerRight {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  width: 80%;
}

.headerRight > .studentActions {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {

  .customHeader > .headerRight {
    flex-direction: column-reverse;
    padding: 10px;
    align-items: flex-end;
  }

  .headerRight > .studentActions {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}