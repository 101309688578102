@import url("https://fonts.googleapis.com/css?family=Chivo");

.modal {
  background-color: #fff;
  width: 25%;
  padding: 0px 20px 20px 20px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Chivo", sans-serif !important;
  outline: 0;
}

.modalActions {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.marginTop {
  margin-top: 20px;
}

