.bottomArea {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 50px;
  margin: 2% 0;
  width: 100%;
}

.logoutButton > .ui.basic.button {
  background-color: var(--neutral-color);
  border: 2.5px solid var(--button-color) !important;
  border-radius: 0 !important;
  color: var(--button-color) !important;
  font-size: var(--normal) !important;
  text-transform: uppercase !important;
  min-width: 135px !important;
  height: 44px;
}

.logoutButton > .ui.basic.button:hover {
  background-color: var(--button-color) !important;
  color: var(--neutral-color) !important;
}